/**
 * 3001-zg-CMSBreadcrumbsChanger.js
 *
 * Plugin to store and change the breadcrumb with the URL blog options filters
 *
 * @author: Javier Ramos <framos[at]kooomo.com>
 *
 */


(function ( $, _ ) {
    'use strict';

    // Establish the root object ('window' in the browser)
    var root = this;

    /**
     * @selector data-zg-role="blog-content" The plugin start if there is the selector in the dom when the page load
     */
    var SELECTOR = '[data-zg-role="blog-content"]';



    // SCRIPT OPTIONS

    /**
     * @param {string}  [link_blog] Used to get the url if present
     * @param {string} [link_breadcrumb] Used to change the href if present
     *
     */
    var DEFAULTS = {
        link_blog:     '[data-zg-role="link-blog"]',
        link_breadcrumb: 	 '[data-zg-role="link-breadcrumb"]'
    };


    // CMS BREADCRUMB CHANGER PLUGIN CLASS DEFINITION
    // ===========================

    /**
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var CMSBreadcrumbsChanger = function ( element, options ) {
        this.$element = $( element );
        this.options = _.clone( DEFAULTS );

        this.$link_blog     = $( this.options.link_blog );
        this.$link_breadcrumb 	  = $( this.options.link_breadcrumb );

        this.__setEventHandlers();
    };


    /**
     *
     * @returns String
     */
    CMSBreadcrumbsChanger.prototype.getUrl = function () {
        return window.location.href;
    };

    /**
     *
     * @param options
     */
    CMSBreadcrumbsChanger.prototype.updateOptions = function ( options ) {
        _.extendOwn( this.options, options || {} );
        _.extendOwn( this.request, options || {} );
    };


    /**
     *
     * @private
     */
    CMSBreadcrumbsChanger.prototype.__setEventHandlers = function () {

        var that = this;

        $( document ).on( 'click',that.options.link_blog, function() {
            var url = btoa( that.getUrl() );
            root.zgCreateCookie( 'ZGBlog', url, 1 );
        });

        $( document ).ready(  function() {
            if ( $( that.options.link_breadcrumb ).length > 0 ) {
                var cookie_blog_home_url = atob( root.zgReadCookie('ZGBlog') );
                $( that.options.link_breadcrumb ).attr('href', cookie_blog_home_url);
            }

        });
    };


    // CMS BREADCRUMB CHANGER PLUGIN DEFINITION
    // ============================

    function Plugin ( option, updateOptions ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.CMSBreadcrumbsChanger' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.CMSBreadcrumbsChanger', (data = new CMSBreadcrumbsChanger( this, options )) );
            } else if ( updateOptions && typeof option === 'object' ) {
                data.updateOptions( option );
            }

        } );
    }

    $.fn.zgCMSBreadcrumbsChanger              = Plugin;
    $.fn.zgCMSBreadcrumbsChanger.Constructor = CMSBreadcrumbsChanger;


    // CMS BREADCRUMB CHANGER PLUGIN DATA-API
    // ===================

    // default product - called on page load
    $( function () {
        $( SELECTOR ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );

}.call( this, jQuery, _ ));
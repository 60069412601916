// add your site specific javascript here
$( document ).ready(function() {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	//Return to top
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click( function() {
		console.log("clicked button!");
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});

	var $searchContainer = $( '#searchContainer' );
	$( '[data-zg-role="btn-search"]' ).click( function(){
		if( $searchContainer.hasClass( 'd-none' ) ){
			$searchContainer.removeClass( 'd-none' );
		}
		else {
			$searchContainer.addClass( 'd-none' );
		}
	});


	//Increasing-decreasing qty input
	$( '[data-zg-role="change-qty"]' ).click( function(){
		event.preventDefault();
		var type = $( this ).data( 'type' );
		var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
		var maxQty = $inputQtySibling.data( 'max' );
		var floatQty = $inputQtySibling.data( 'floatQty' );
		var qty = $inputQtySibling.val();
		var step;

		if( floatQty ){
			step = 0.01;
		}
		else {
			step = 1;
		}
		qty = Number(qty);

		$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
		if( type == "minus" ){
			//If qty is 0, we will disable the current minus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == 0 ){
				$( this ).attr( 'disabled', true );
			}
			else{
				qty = qty - step;
			}
		}
		else {
			//If qty is equal to maxQty, we will disable the current plus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == maxQty ){
				$( this ).attr( 'disabled', true );
			}
			else {
				qty = qty + step;
			}
		}

		$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
	});

	$( '[data-km-role="notification-close"]' ).click(function () {
		$( this ).parent( '.notification' ).remove(); //Removing the notification container
		$( 'body' ).removeClass( 'body-notification-on' ); //Removing the main marginTop
		zgCreateCookie( 'km_notification', 'false', 1 ); //Cookie created for not displaying it again for 5min
	});
	/* Video modal */
	$('[data-zg-role="video-modal"]').on('show.bs.modal', function(event){

		const $button = $(event.relatedTarget),
			video_data = $button.data(),
			$modalContent = $(video_data.target).find('.modal-content'),
			template = video_data.videoPlatform === 'youtube' ? 'youtube-embed' : 'wistia-embed';

		var $embed = $( handlebarsTemplates.render( template, video_data ) );
		$modalContent.html( $embed );
	});

	$('[data-zg-role="video-modal"]').on('hide.bs.modal', function (event) {
		$(event.target).find('.modal-content').html('<div class="modals__loading-overlay"></div>');
	});

	/* removes the loading overlay on youtube videos */
	window.videoLoadHandler = function(el){
		$(el)
			.closest('.modal-content')
			.find('.modals__loading-overlay')
			.remove();
	};

	/* on load event for all wistia videos */
	window._wq = window._wq || [];
	_wq.push({ id: "_all", onReady: function(video) {
		$(video.container)
			.closest('.modal-content')
			.find('.modals__loading-overlay')
			.remove();

		console.log(video);
	}});

	/* End Video modal */

	window.checkboxLabelHandler = function(){
		const checkboxes = document.querySelectorAll('[type="checkbox"]');
		if(!checkboxes)return;

		checkboxes.forEach(function(el) {
			el.addEventListener('change', function(event){
				if(event.target.checked){
					event.target.labels.forEach(function(label){
						label.classList.add('checked');
					});
					return;
				}

				event.target.labels.forEach(function(label){
					label.classList.remove('checked');
				});
				return;
			});
		});
	}();

	/* run the find a partner/find an app pages */
	$( '#find-partner-container').zg_filters();


});

/*New theme*/

$( document ).ready(function() {

	$('.mobile-menu-item').on('click',function( event ) {
		event.preventDefault();
		$(this).nextAll('ul').first().toggle();
	});

	$('.menu-toggle .fa-bars').on('click',function() {
		$('.mobile-menu').css('max-height', '1000px');
		$(this).addClass('d-none');
		$(this).nextAll('.fa-times').first().removeClass('d-none');
	});

	$('.menu-toggle .fa-times').on('click',function() {
		$('.mobile-menu').css('max-height', '0');
		$(this).addClass('d-none');
		$(this).prevAll('.fa-bars').first().removeClass('d-none');
	});
	$('.nav-menu-main').on('click',function( event ) {
		event.preventDefault();
	});

	$('#user_menu').on('click',function( event ) {
		event.preventDefault();
	});

});
/*Quote slider*/
const quoteClientSlider = document.querySelector('.quote-clients-slider');

if( quoteClientSlider) {
	$( '.quote-clients-slider' ).slick({
		dots: false,
		infinite: true,
		speed: 400,
		adaptiveHeight: false,
		arrows: false,
	});
}

/*Logo Slider*/
let logoCarousel = document.querySelectorAll('.logo-slider');

if (logoCarousel) {

	logoCarousel.forEach( (el) =>{
		let items = el.querySelectorAll('.carousel-item');
		const minPerSlide = items.length;
		el.querySelector('.carousel-inner').dataset.elements = minPerSlide.toString();
		items.forEach((el) => {
			let next = el.nextElementSibling
			for (var i=1; i<minPerSlide; i++) {
				if (!next) {
					// wrap carousel by using first child
					next = items[0]
				}
				let cloneChild = next.cloneNode(true)
				el.appendChild(cloneChild.children[0])
				next = next.nextElementSibling
			}
		});
	});

}
/*Animation*/

function reset_animation() {
	var el = document.querySelector('.wrapper-animated svg path');
	var newone = el.cloneNode(true);
	el.parentNode.replaceChild(newone, el);
}
if ( document.querySelector('.wrapper-animated svg path') != null ) {
	setInterval(reset_animation, 6000);
}

function normalizeSlideHeights() {
	$('.quote-client-slider').each(function(){
		let items = $('.carousel-item', this);
		// reset the height
		items.css('min-height', 0);
		// set the height
		let maxHeight = Math.max.apply(null,
			items.map(function(){
				return $(this).outerHeight()}).get() );
		items.css('min-height', maxHeight + 'px');
	})
}

$(window).on(
	'load resize orientationchange',
	normalizeSlideHeights);
/**
 * Plugin for validate a form.
 * When the user try to submit a form, if the form has the selector, the plugin check the required input.
 * If some required input is not set, stop the submit, add "has-error" in the form group and add span with error message under each required input not set
 *
 * @author: Botond Szasz <bszasz@zerogrey.com>
 *
 */

(function ($) {
	'use strict'

	/**
	 * @selector data-zg-validate="to-validate" The plugin start if there is the selector in the dom when the page load
	 */
	const toggle = '[data-zg-validate="to-validate"]'

	const ZG_Validate = function (element, config) {
		const self = this

		this.init = function (element, config) {
			self.$element = $(element)
			self.$eventBus = $(document)

			self.$form = self.$element.is('form') ? self.$element : self.$element.closest('form')

			self.config = $.extend(
				{},
				config,
				{
					ignore: '.ignore', // so it doesn't ignore hidden input fields, but it will ignore anything with the "ignore" class
					rules: {
						hiddenRecaptcha: {
							required() {
								if (grecaptcha.getResponse() == '') {
									return true
								}

								return false
							}
						}
					},
					ajax_form: false,
					errorElement: 'span',
					errorClass: 'help-block',
					highlight(element) {
						const $element = $(element)
						const type = $element.prop('type')

						if (type === 'radio') {
							$element
								.closest('.radio')
								.addClass('text-danger')
						} else if (type === 'checkbox') {
							$element
								.closest('.custom-checkbox')
								.find('.help-block')
								.addClass('text-danger')
							$element
								.closest('.custom-checkbox')
								.find('.custom-control-label')
								.addClass('text-danger')
						} else {
							$element.addClass('is-invalid')
							$element.closest('.form-group')
								.find('label')
								.addClass('text-danger')
							$element.closest('.form-group')
								.find('.help-block')
								.addClass('text-danger')
						}
					},
					unhighlight(element) {
						const $element = $(element)
						const $group		= $element.closest('text-danger')
						let removeClass = true

						if ($group.hasClass('multi-validation-group')) {
							removeClass = $group.find(self.config.errorElement + '.' + self.config.errorClass + ':not(:hidden)').length === 0
						}

						if (removeClass) {
							$element.removeClass('is-invalid')
							$element.closest('.form-group').find('.text-danger').removeClass('text-danger')
							$element.closest('.custom-checkbox').find('.text-danger').removeClass('text-danger')
							$group.removeClass('text-danger')
						}
					},
					errorPlacement(error, element) {
						const type = element.prop('type')

						if (element.parent('.input-group').length) {
							error.insertAfter(element.parent())
						} else if (type === 'checkbox' || type === 'radio') {
							element.parent().append(error)
						} else {
							error.insertAfter(element)
						}
					}
				},
				self.$form.data('config') || {}
			)

			if (self.config.avoidDoubleClick) {
				self.config.submitHandler = function (form) {
					$(form).find('[type="submit"]').prop('disabled', true)
					form.submit()
				}
			}

			self.validator = self.$form.validate(self.config)

			if (!self.config.ajax_form) {
				self.$form.on('submit', e => {
					return self.validate(e)
				})
			}
		}

		this.resetForm = function () {
			self.validator.resetForm() // this removes the error messages but not the error colors

			self.$form.find('label').removeClass('text-danger')
			self.$form.find('.form-group').removeClass('text-danger')
		}

		this.validate = function (event) {
			self.is_valid = self.$form.valid()

			if (!self.is_valid && event) {
				event.stopPropagation()
				event.preventDefault()
			}

			return self.is_valid
		}

		this.update = function () {
			self.validator.form()
		}

		this.reset = function () {
			self.resetForm()
		}

		this.init(element, config)
	}

	$.fn.zg_validate = function (config) {
		return this.each(function () {
			const $this = $(this)
			const data = $this.data('zgValidator')
			if (!data) {
				$this.data('zgValidator', new ZG_Validate(this, config))
			}
		})
	}

	$(() => {
		$(toggle).zg_validate(window.ZG_CONFIG)
	})

	$(document).on('zg.addressForm.ready', () => {
		$(toggle).zg_validate(window.ZG_CONFIG)
	})
})(jQuery);

// ----------------------------------------------------------------------------

(function ($) {
	'use strict'

	// CHECK THE INTEGER NUMBER IN input[number]

	$(document).on('paste input', '#confirm_password , #registeruser_pwd_confirm', e => {
		e.preventDefault()
	})

	$(document).on('keyup', 'input[type="text"]', function (e) {
		if (jQuery(this).val() == ' ') {
			e.preventDefault()
			jQuery(this).val('')
		}
	})

	$(document).on('paste input', 'input[type="postcode"]', e => {
		e.preventDefault()
	})

	$(document).on('paste input', 'input[type="tel"]', e => {
		e.preventDefault()
	})

	$(':input[type="number"]').keydown(event => {
		// Allow only backspace and delete
		if (event.keyCode === 46 || event.keyCode === 8 || event.keyCode === 13 || event.keyCode === 108) {
			// let it happen, don't do anything
		} else if (event.keyCode < 48 || (event.keyCode > 57 && event.keyCode < 96) || event.keyCode > 105) {
			// Ensure that it is a number and stop the keypress
			event.preventDefault()
		}
	})

	/* validation code started */

	$(document).on('keypress', '#first_name , #last_name  , #registershipping_address_first_name , #registershipping_address_last_name ', e => {
		if ((e.which < 45 || e.which > 45) && (e.which < 65 || e.which > 90) && (e.which < 97 || e.which > 122) && (e.which != 11) && (e.which != 0)) {
			e.preventDefault()
		}
	})

	$(document).on('keypress', '#post_code , #registershipping_address_company_name, #registershipping_address_p_iva , #registershipping_address_post_code', e => {
		if ((e.which < 65 || e.which > 90) && (e.which < 97 || e.which > 122) && (e.which < 48 || e.which > 57) && (e.which != 11) && (e.which != 0) && (e.which != 32)) {
			e.preventDefault()
		}
	})

	$(document).on('keypress', ' #city , #registershipping_address_city ', '#addr_1 , #registershipping_address_addr_1 , #registershipping_address_addr_2 , #registershipping_address_addr_3 ', e => {
		if ((e.which < 65 || e.which > 90) && (e.which < 97 || e.which > 122) && (e.which < 48 || e.which > 57) && (e.which != 11) && (e.which != 0) && (e.which != 32) && (e.which != 44) && (e.which != 39) && (e.which != 45) && (e.which != 33) && (e.which != 38)) {
			e.preventDefault()
		}
	})

	$(document).on('keypress', '#registershipping_address_telephone ,#registershipping_address_mobile, #telephone , #mobile ', e => {
		if ((e.which < 48 || e.which > 57) && (e.which != 43) && (e.which != 11) && (e.which != 0) && (e.which != 32) && (e.which != 40) && (e.which != 41) && (e.which != 45)) {
			e.preventDefault()
		}
	})

	/* validation code ended */
})(jQuery);

// ----------------------------------------------------------------------------

(function ($) {
	'use strict'

	// JQUERY VALIDATE TRANSLATED MESSAGES
	if (window.SGL_JS_LANG && SGL_JS_LANG !== 'en') {
		const lang = SGL_JS_LANG === 'pt' ? 'pt_PT' : SGL_JS_LANG

		$.getScript('//ajax.aspnetcdn.com/ajax/jquery.validate/1.15.1/localization/messages_' + lang + '.js')
	}
})(jQuery)
